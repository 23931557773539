import React, { useState, useRef } from "react"
import HeroCarousel from "../components/heroCarousel"
import Imgix from "react-imgix"
import NewLayout from "../layout/newLayout"
import Rating from "../components/rating"
import SEO from "../components/seo"
import Slider from "react-slick"
import { Link } from "gatsby"
import Text from "../components/text"
import YouTube from "react-youtube"
import bvReviewDataJSON from "../data/bvreviews.json"
import cn from "classnames"
import styles from "../styles/scss/templates/ezpods-page.module.scss"
import { supSub } from "../services/util"


const EzpodsPage = ({ ...props }) => {
    // Page Context
    const pageData = props.pageContext.page
    const firstContent = pageData.content[0]
    const SecondContent = pageData.content[1]
    const carouselItems = pageData.carousel
    const productEZPods = pageData.relatedProducts[0]
    // End of Page Context


    // State Based & Ref
    const [selectedProductIndex, setSelectedProductIndex] = useState(0)
    let sliderRef = useRef(null)
    let sliderRefMovil = useRef(null)
    // End of State Based

    // Get ProductReviews
    const productReviews = id => {
        return bvReviewDataJSON.find(review => review.id === id)
    }

    // Carousel Functions
    const arrowsControl = (productIndex, flow) => {
        const isNext = flow === "next";
        const edges = { next: carouselItems.length - 1, prev: 0 }

        let slidePosition = isNext ? productIndex + 1 : productIndex - 1;
        slidePosition = slidePosition >= edges.next ? edges.next : slidePosition;
        slidePosition = slidePosition <= edges.prev ? edges.prev : slidePosition;
        handleProductChange(slidePosition)
    }
    const handleProductChange = (index) => {
        const slick = sliderRef.current
        slick.slickGoTo(index)
        setSelectedProductIndex(index)
    }
    const ProductCanourStoryArrow = props => {
        let className = props.type === "next" ? "nextArrow" : "prevArrow"
        className += " arrow"
        const char =
            props.type === "next" ? (
                <svg
                    className="fa fa-angle-right"
                    aria-hidden="true"
                    style={{ right: "5%" }}
                    width="20"
                    height="28"
                    viewBox="0 0 20 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 26L16 14L2 2" stroke="#FFF200" strokeWidth="5" />
                </svg>
            ) : (
                <svg
                    className="fa fa-angle-left"
                    aria-hidden="true"
                    style={{ left: "5%" }}
                    width="20"
                    height="28"
                    viewBox="0 0 20 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 2L4 14L18 26" stroke="#FFF200" strokeWidth="5" />
                </svg>
            )
        return (
            <div
                className={className}
                role="button"
                tabIndex={0}
                onClick={() => arrowsControl(props.productIndex, props.type)}
            >
                {char}
            </div>
        )
    }

    const productCansCarouselsettings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        className: "center",
        centerMode: true,
        centerPadding: "20px",
        mobileFirst: true,
        swipe: false,
        initialSlide: selectedProductIndex,
        dots: true,
        appendDots: dots => (
            <div>
                <div className={styles.slickDotsCustom}
                    style={{
                        padding: "10px"
                    }}>
                    <ul className={styles.slickDotsList} style={{ margin: "0px" }}>

                        {dots.map((dot, index) => {
                            return (
                                <li
                                    key={index}
                                    className={
                                        index === selectedProductIndex ?
                                            styles.customSlickItemActive :
                                            styles.customSlickItem}>
                                    {
                                        dot.props.children
                                    }
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        ),
        nextArrow: (
            <ProductCanourStoryArrow
                type="next"
                productIndex={selectedProductIndex}
            />
        ),
        prevArrow: (
            <ProductCanourStoryArrow
                type="prev"
                productIndex={selectedProductIndex}
            />
        ),
        afterChange: function (index) {
            setSelectedProductIndex(index)
        },
        responsive: [
            {
                breakpoint: 816,
                settings: {
                    initialSlide: 0,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    infinite: false,
                    centerMode: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    initialSlide: 0,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    infinite: false,
                    centerMode: false,
                },
            },
        ],
    }

    const productCansCarouselsettingsMovil = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: styles.carouselMobile,
        centerMode: false,
        centerPadding: "20px",
        mobileFirst: true,
        initialSlide: selectedProductIndex,
        dots: true,
        touchMove: true,
        swipe: true,
        appendDots: dots => (
            <div>
                <div className={styles.slickDotsCustom}
                    style={{
                        padding: "10px"
                    }}>
                    <ul className={styles.slickDotsList} style={{ margin: "0px" }}>

                        {dots.map((dot, index) => {
                            return (
                                <li
                                    key={index}
                                    className={
                                        index === selectedProductIndex ?
                                            styles.customSlickItemActive :
                                            styles.customSlickItem}>
                                    {
                                        dot.props.children
                                    }
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        ),
        afterChange: function (index) {
            setSelectedProductIndex(index)
        },
    }

    // End of Carousel Functions

    return (
        <NewLayout>
            <SEO page={pageData} />
            <div className={styles.heroTopMinLine}></div>
            {pageData.desktopImages && (
                <HeroCarousel
                    desktopImages={[{ url: pageData.heroDesktop.url }]}
                    mobileImages={[{ url: pageData.heroMobile.url }]}
                />
            )}
            <div className={styles.ezpodsWrapper}>
                <div className={styles.videoWrapper}>
                    <div className={styles.iframeContainer}>
                        <YouTube
                            videoId={firstContent.video.providerUid}
                            opts={{
                                playerVars: {
                                    rel: 0,
                                },
                            }}
                        />
                    </div>
                </div>

                <div 
                    style={{
                        backgroundImage:
                            'url("/img/industrial-page/blue-gradient.png")',
                        overflow: "hidden",
                    }}
                    className={styles.carouselContainer}>
                    <div className={styles.carouselWrapper}>
                        <div
                            className={styles.carouselImageContainer}
                            
                        >

                            <div className="slider-specialist-desktop">
                                <Slider
                                    ref={sliderRef}
                                    {...productCansCarouselsettings}
                                    className={styles.productCansCarousel}
                                >
                                    {carouselItems &&
                                        carouselItems.map(
                                            (item, index) => {
                                                return (
                                                    <div
                                                        className={cn(
                                                            styles.blueCanImageContainer,
                                                            `ezpodsProductCan`
                                                        )}
                                                        key={index}
                                                    >
                                                        <Text
                                                            className={styles.carouselTitle}
                                                            value={item.title}
                                                        />
                                                        <div className={styles.carouselContent}>
                                                            <Imgix
                                                                src={item.image.url}
                                                                alt={item.image.alt || `ezpod degreaser feature ${item.title}`}
                                                                sizes="100vm"
                                                                className={styles.productCansCarouselImageSelected}
                                                                htmlAttributes={{ alt: item.image.alt || `ezpod degreaser feature ${item.title}` }}
                                                            />

                                                            <div
                                                                className={styles.textContainer}
                                                                dangerouslySetInnerHTML={{ __html: supSub(item.body) }}
                                                            />
                                                        </div>

                                                    </div>
                                                )
                                            }
                                        )}
                                </Slider>
                            </div>
                            <div className="slider-specialist-movil">
                                <Slider
                                    ref={sliderRefMovil}
                                    {...productCansCarouselsettingsMovil}
                                    className={styles.productCansCarousel}
                                >
                                    {carouselItems &&
                                        carouselItems.map(
                                            (item, index) => {
                                                return (
                                                    <div
                                                        className={
                                                            styles.blueCanImageContainer
                                                        }
                                                        key={index}
                                                    >
                                                        <Text
                                                            className={styles.carouselTitle_mobile}
                                                            value={item.title}
                                                        />
                                                        <div className={styles.carouselContentMobile}>
                                                            <Imgix
                                                                src={item.image.url}
                                                                alt={item.image.alt || `ezpod degreaser feature ${item.title}`}
                                                                sizes="100vm"
                                                                className={styles.productCansCarouselImageSelected}
                                                                htmlAttributes={{ alt: item.image.alt || `ezpod degreaser feature ${item.title}` }}
                                                            />

                                                            <div
                                                                className={styles.textContainer}
                                                                dangerouslySetInnerHTML={{ __html: supSub(item.body) }}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.productWrapper}>
                    <div className={styles.productImage}>

                        <Imgix
                            src={productEZPods.listImage.url}
                            alt={productEZPods.listImage.alt || `ezpod degreaser feature }`}
                            width={546}
                            htmlAttributes={{ alt: productEZPods.listImage.alt || `ezpod degreaser feature }` }}
                        />


                        {/* <div
                            className={styles.whereToBuyWrapper}
                            style={{ marginTop: '2rem', justifyContent: "flex-start" }}>
                            <ProductButtons
                                className={styles.whereToBuy}
                                product={productEZPods}
                                verticalButtons={true}
                                withoutAnchor={true}
                            />
                        </div> */}
                    </div>
                    <div className={styles.productInfo}>
                        <Link to={`/products/${productEZPods.slug}/`}>
                        <Text
                            className={styles.productName}
                            value={productEZPods.displayName}
                        />
                        </Link>
                        
                        <div className={styles.productRating}>
                            <Text className={styles.productSizes}>
                                {productEZPods.subtitle}
                            </Text>
                            <Rating
                                value={productReviews(productEZPods.bazaarVoiceId)}
                                bold
                                spacing
                                newStyle
                            />
                        </div>
                        <div
                            className={styles.textContainer}
                            dangerouslySetInnerHTML={{ __html: supSub(productEZPods.content[0].body) }} />
                        {/* <div
                            className={styles.whereToBuyWrapper}
                            style={{ marginTop: '2rem', justifyContent: "flex-start" }}>
                            <ProductButtons
                                className={styles.whereToBuy}
                                product={productEZPods}
                                verticalButtons={true}
                                withoutAnchor={true}
                            />
                        </div> */}
                    </div>
                </div>
                <div className={styles.videoWrapper}>
                    <div className={styles.iframeContainer}>
                        <YouTube
                            
                            videoId={SecondContent.video.providerUid}
                            opts={{
                                playerVars: {
                                    rel: 0,
                                    color: 'white'
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
            <style>
                {`
                    .slider-specialist-desktop{
                        display: none;
                    }

                    @media (min-width: 816px) {
                        .slider-specialist-desktop{
                        display: block;
                        }
                        .slider-specialist-movil{
                        display: none;
                        }
                    }
                `}
            </style>
        </NewLayout>
    )
}

export default EzpodsPage
