import React, { Component } from 'react';
import PropTypes from "prop-types"
import styles from '../styles/scss/components/heroCarousel.module.scss'
import Slider from "react-slick";
import cn from 'classnames';
import {
    isMobile
} from "react-device-detect";

class HeroCarousel extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 7000,
            fade: true,
            arrows: false
        };

        const heroDesktopArray = this.props.desktopImages;
        const heroMobileArray = this.props.mobileImages;
        
        return (
            <Slider {...settings}>

                {isMobile ?
                    heroMobileArray.map((item, index) => {
                        return (
                            <div key={index}>
                                <div style={{ backgroundImage: "url(" + item.url + ")" }} className={cn(styles.responsiveImageMobile)} />
                            </div>
                        );
                    }) : heroDesktopArray.map((item, index) => {
                        return (
                            <div key={index}>
                                <img src={item.url} className={cn(styles.responsiveImageDesktop)} alt="Page Heros"/>
                            </div>
                        );
                    })}
            </Slider>
        );
    }
}

HeroCarousel.propTypes = {
    children: PropTypes.node,
    desktopImages: PropTypes.array,
    mobileImages: PropTypes.array
}

export default HeroCarousel
